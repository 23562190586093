<!--
 * @Author: gaojingran
 * @Date: 2021-04-20 10:33:09
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2024-01-16 16:22:39
 * @Description: 导出报价单 1405425104765755393,1405432039175004162
-->

<style lang="less" scoped>
.pdf-quotation {
  background-color: #fff;
  min-height: 100%;
  min-width: min-content;
}
</style>

<template>
  <div class="pdf-quotation">
    <Quatation :isPdf="true" :ids="$route.query.ids ? $route.query.ids.split(',') : []" />
  </div>
</template>

<script>
import withPdfPreconditions from '@/mixins/withPdfPreconditions'
import Quatation from '@/views/modules/Kingsoft/order/orderInfo/component/QuotationDrawer/QuotationPdf'

export default {
  name: 'PdfQuation',
  mixins: [withPdfPreconditions],
  components: {
    Quatation,
  },
}
</script>
